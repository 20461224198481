import { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';

import { FilterBadgeProps } from '../types';

import { usePaginatedUsers } from '../../../main/users/hooks/usePaginatedUsers';

import { Loading } from '../../../helpers/Loading';
import { ItemsFilterBadge } from '../ItemsFilterBadge';

import {
  FETCH_ITEM_TEAM_USERS_QUERY,
  FetchItemTeamUsersQueryResponse
} from '../../FormFields/UsersListPopoverField/components/UsersListPopoverFieldList/queries/fetchItemTeamUsers.query';

import { usersKeys } from '../../../locales/keys';

import { UserCache } from '../../../main/users/UserCache';

type UsersFilterBadgeProps = FilterBadgeProps;

function UsersFilterBadge({
  filters,
  i18nLabel = usersKeys.plural,
  name,
  onRemoveFilter
}: UsersFilterBadgeProps) {
  const selectedUserIds = get(filters, name, []);

  const { users, usersFetched, usersLoading, filterUsers } =
    usePaginatedUsers<FetchItemTeamUsersQueryResponse>({
      cacheKey: UserCache.usersFilterCacheKey(name),
      query: FETCH_ITEM_TEAM_USERS_QUERY,
      initialFilters: {
        id: { in: selectedUserIds }
      }
    });

  const userItems = useMemo(
    () =>
      users.map(({ id, fullName }) => ({
        id: toString(id),
        label: fullName
      })),
    [users]
  );

  useEffect(() => {
    if (!isEmpty(selectedUserIds)) {
      filterUsers({ id: { in: selectedUserIds } });
    }
  }, [filterUsers, selectedUserIds]);

  return (
    <Loading loaded={!usersLoading && usersFetched} withoutLoader>
      <ItemsFilterBadge
        filters={filters}
        i18nLabel={i18nLabel}
        items={userItems}
        name={name}
        onRemoveFilter={onRemoveFilter}
      />
    </Loading>
  );
}

export default UsersFilterBadge;
