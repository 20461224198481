import { Control, Controller, FieldPath } from 'react-hook-form';

import { I18nText, IsDisabled } from '../../../types';
import { UserID } from '../../../main/users/usersTypes';
import { IconsEnum } from '../../../assets/icons/types';

import { UsersListPopoverFieldControl } from './components/UsersListPopoverFieldControl';

interface UsersListPopoverFieldProps<FormDataType> {
  buttonIcon?: IconsEnum;
  buttonI18nText?: I18nText;
  control: Control<FormDataType>;
  disabled?: IsDisabled;
  i18nLabel?: I18nText;
  isEditingUsers?: boolean;
  isHidden?: boolean;
  name: FieldPath<FormDataType>;
  onToggleIsEditingUsers: () => void;
}

function UsersListPopoverField<FormDataType>({
  buttonIcon,
  buttonI18nText,
  control,
  disabled,
  i18nLabel,
  isEditingUsers,
  isHidden,
  name,
  onToggleIsEditingUsers
}: UsersListPopoverFieldProps<FormDataType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <UsersListPopoverFieldControl
          buttonIcon={buttonIcon}
          buttonI18nText={buttonI18nText}
          disabled={disabled}
          fieldName={name}
          i18nLabel={i18nLabel}
          isEditingUsers={isEditingUsers}
          isHidden={isHidden}
          onChange={onChange}
          onToggleIsEditingUsers={onToggleIsEditingUsers}
          value={value as UserID[]}
        />
      )}
    />
  );
}

export default UsersListPopoverField;
