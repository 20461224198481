import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { UserID, UserUUID } from '../../../../../main/users/usersTypes';

import { useSelectedUsers } from '../../../../../main/common/modalButtons/InviteUsersModalButton/hooks/useSelectedUsers';

import { InviteUsersModalBody } from '../../../../../main/common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody';

import { PureIconButtonHelper } from '../../../../buttons/PureIconButtonHelper';
import { PureButtonHelper } from '../../../../buttons/PureButtonHelper';

import { usersKeys } from '../../../../../locales/keys';

interface UsersListPopoverFieldAddUserButtonProps {
  icon?: IconsEnum;
  initialUserUuids?: UserUUID[];
  isEditingUsers: boolean;
  i18nText?: string;
  onAddUsers: (ids: UserID[]) => void;
  onToggleIsEditingUsers: () => void;
}

function UsersListPopoverFieldAddUserButton({
  icon = IconsEnum.PLUS_SOLID,
  initialUserUuids,
  isEditingUsers,
  i18nText = usersKeys.add,
  onAddUsers,
  onToggleIsEditingUsers
}: UsersListPopoverFieldAddUserButtonProps) {
  const {
    selectedUsers,
    selectedUserIds,
    handleSelectUser,
    handleDeselectUser,
    handleDeselectAllUsers
  } = useSelectedUsers();

  const handleAddUserIds = useCallback(() => {
    onAddUsers(selectedUserIds);
    onToggleIsEditingUsers();
    handleDeselectAllUsers();
  }, [
    handleDeselectAllUsers,
    onAddUsers,
    onToggleIsEditingUsers,
    selectedUserIds
  ]);

  return isEditingUsers ? (
    <>
      <InviteUsersModalBody
        invitedUserUuids={initialUserUuids}
        selectedUsers={selectedUsers}
        selectedUserIds={selectedUserIds}
        onSelectUser={handleSelectUser}
        onDeselectUser={handleDeselectUser}
      />
      <div className="flex items-center py-4 justify-between bg-white dark:bg-gray-850 sticky bottom-0">
        <div className="flex items-center space-x-2">
          <PureButtonHelper
            id="btn-cancel-add-users"
            className="inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            i18nText="words.cancel"
            onClick={onToggleIsEditingUsers}
          />
        </div>
        <div className="flex items-center space-x-2">
          <PureButtonHelper
            id="btn-add-users"
            className="inline-flex items-center px-2 sm:px-4 py-2 text-sm font-medium rounded-md shadow-sm hover:shadow-md text-white bg-blue-500 hover:bg-blue-600"
            i18nText="words.add"
            onClick={handleAddUserIds}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="-mx-2">
      <PureIconButtonHelper
        className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 w-full"
        icon={icon}
        iconClassName="h-9 w-9 p-2 mr-2"
        i18nText={i18nText}
        onClick={onToggleIsEditingUsers}
      />
    </div>
  );
}

export default UsersListPopoverFieldAddUserButton;
