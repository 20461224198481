import React, { useCallback, useMemo } from 'react';
import cl from 'classnames';
import { contrast } from 'chroma-js';

import { IconsEnum } from '../../../../assets/icons/types';
import { IsDisabled } from '../../../../types';
import {
  ColorsSelectItemValue,
  ColorsSelectItemLabel,
  ColorsSelectItemColor,
  ColorsSelectOnToggleItem
} from '../../ColorsSelect.types';

import { PureTooltipIconButtonHelper } from '../../../buttons/PureTooltipIconButtonHelper';

interface ColorsSelectItemProps {
  value: ColorsSelectItemValue;
  label: ColorsSelectItemLabel;
  color: ColorsSelectItemColor;
  checked: boolean;
  disabled?: IsDisabled;
  onToggleItem: ColorsSelectOnToggleItem;
}

function ColorsSelectItem({
  value,
  label,
  color,
  checked,
  onToggleItem
}: ColorsSelectItemProps) {
  const handleClick = useCallback<() => void>(() => {
    onToggleItem(value);
  }, [value, onToggleItem]);

  const style = useMemo<React.CSSProperties>(
    () => ({
      backgroundColor: color
    }),
    [color]
  );

  const iconClassName = cl(
    'h-5 w-5',
    { 'dark:text-white': value === '' },
    value && contrast(color, 'white') > 2 ? 'text-white' : 'text-black'
  );

  return (
    <PureTooltipIconButtonHelper
      className="p-2 h-8 w-full rounded flex items-center justify-center hover:opacity-75"
      style={style}
      tooltipSingleton
      tooltipText={label}
      icon={checked ? IconsEnum.CHECK : null}
      iconClassName={iconClassName}
      onClick={handleClick}
    />
  );
}

export default ColorsSelectItem;
