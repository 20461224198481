import React, { useCallback } from 'react';

import {
  YesNoAnyRadioButtonsValue,
  YesNoAnyRadioButtonsItemI18nText,
  YesNoAnyRadioButtonsOnChange
} from '../../YesNoAnyRadioButtons.types';

import { Translate } from '../../../Translate';

export interface YesNoAnyRadioButtonsItemProps {
  name: string;
  value: YesNoAnyRadioButtonsValue;
  buttonValue: YesNoAnyRadioButtonsValue;
  i18nText: YesNoAnyRadioButtonsItemI18nText;
  onChange: YesNoAnyRadioButtonsOnChange;
}

function YesNoAnyRadioButtonsItem({
  name,
  value,
  buttonValue,
  i18nText,
  onChange
}: YesNoAnyRadioButtonsItemProps) {
  const handleChange = useCallback(() => {
    onChange(buttonValue);
  }, [buttonValue, onChange]);

  return (
    <div className="mr-4">
      <label className="inline-flex items-center">
        <input
          type="radio"
          className="basic-radio"
          name={name}
          value={buttonValue}
          checked={value === buttonValue}
          onChange={handleChange}
        />
        <span className="ml-2">
          <Translate id={i18nText} />
        </span>
      </label>
    </div>
  );
}

export default YesNoAnyRadioButtonsItem;
