import { useMemo } from 'react';
import toString from 'lodash/toString';

import { FilterBadgeProps } from '../types';

import {
  FETCH_TAG_NAMES_QUERY,
  FetchTagNamesQueryResponse
} from '../../../main/tags/queries/fetchTagNames.query';

import { usePaginatedTags } from '../../../main/tags/hooks/usePaginatedTags';

import { Loading } from '../../../helpers/Loading';
import { ItemsFilterBadge } from '../ItemsFilterBadge';

import { tagsKeys } from '../../../locales/keys';

import { TagCache } from '../../../main/tags/TagCache';

function TagsFilterBadge({ filters, name, onRemoveFilter }: FilterBadgeProps) {
  const { tags, tagsLoading, tagsFetched } =
    usePaginatedTags<FetchTagNamesQueryResponse>({
      cacheKey: TagCache.indexCacheKey(),
      query: FETCH_TAG_NAMES_QUERY
    });

  const tagItems = useMemo(
    () =>
      tags.map(({ id, localizedName }) => ({
        id: toString(id),
        label: localizedName
      })),
    [tags]
  );

  return (
    <Loading loaded={!tagsLoading && tagsFetched} withoutLoader>
      <ItemsFilterBadge
        filters={filters}
        i18nLabel={tagsKeys.plural}
        items={tagItems}
        name={name}
        onRemoveFilter={onRemoveFilter}
      />
    </Loading>
  );
}

export default TagsFilterBadge;
