import React, { useCallback } from 'react';
import cl from 'classnames';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import toString from 'lodash/toString';

import { I18nText, IsDisabled } from '../../../../../types';
import { UserID } from '../../../../../main/users/usersTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  FETCH_ITEM_TEAM_USERS_QUERY,
  FetchItemTeamUsersQueryResponse
} from '../UsersListPopoverFieldList/queries/fetchItemTeamUsers.query';

import { useUsers } from '../../../../../main/users/hooks/useUsers';

import { UsersListPopoverFieldAddUserButton } from '../UsersListPopoverFieldAddUserButton';
import { UsersListPopoverFieldList } from '../UsersListPopoverFieldList';

import { Translate } from '../../../../Translate';

import { UserCache } from '../../../../../main/users/UserCache';

interface UsersListPopoverFieldControlProps {
  buttonIcon?: IconsEnum;
  buttonI18nText?: I18nText;
  disabled?: IsDisabled;
  fieldName: string;
  i18nLabel?: I18nText;
  isEditingUsers?: boolean;
  isHidden?: boolean;
  onChange: (value: UserID[]) => void;
  onToggleIsEditingUsers: () => void;
  value?: UserID[];
}

const initialFetchLimitUsers = 6;
const fetchLimitUsers = 50;

function UsersListPopoverFieldControl({
  buttonIcon,
  buttonI18nText,
  fieldName,
  i18nLabel,
  isEditingUsers,
  isHidden,
  onChange,
  onToggleIsEditingUsers,
  value
}: UsersListPopoverFieldControlProps) {
  const {
    users,
    usersFetched,
    usersLimit,
    filterUsers,
    usersFetchingNextPage,
    hasNextUsersPage,
    loadMoreUsers,
    limitUsers
  } = useUsers<FetchItemTeamUsersQueryResponse>({
    cacheKey: UserCache.indexSelectCacheKey(fieldName),
    query: FETCH_ITEM_TEAM_USERS_QUERY,
    initialFilters: {
      id: { in: value }
    },
    initialLimit: initialFetchLimitUsers
  });

  const handleRemoveUser = useCallback(
    (id: UserID) => {
      const filteredUserIds = filter(
        value,
        (v) => toString(v) !== toString(id)
      );
      filterUsers({
        id: isEmpty(filteredUserIds) ? undefined : { in: filteredUserIds }
      });
      onChange(filteredUserIds);
    },
    [filterUsers, onChange, value]
  );

  const handleAddUsers = useCallback(
    (ids: UserID[]) => {
      const filteredUserIds = compact(concat(value, ids));
      filterUsers({
        id: isEmpty(filteredUserIds) ? undefined : { in: filteredUserIds }
      });
      onChange(filteredUserIds);
    },
    [filterUsers, onChange, value]
  );

  const handleLoadMoreUsers = useCallback<() => void>(() => {
    if (usersLimit === initialFetchLimitUsers) {
      limitUsers(fetchLimitUsers);
    } else {
      loadMoreUsers();
    }
  }, [limitUsers, loadMoreUsers, usersLimit]);

  return (
    <div className={cl('px-4', { hidden: isHidden })}>
      <div className={cl({ 'flex justify-between mb-3': !isEditingUsers })}>
        {isEditingUsers ? null : (
          <h6 className="font-light uppercase">
            <Translate id={i18nLabel} />
          </h6>
        )}
      </div>
      <UsersListPopoverFieldAddUserButton
        icon={buttonIcon}
        initialUserUuids={
          size(value) > 0 ? users?.map((user) => user?.uuid) : null
        }
        isEditingUsers={isEditingUsers}
        i18nText={buttonI18nText}
        onAddUsers={handleAddUsers}
        onToggleIsEditingUsers={onToggleIsEditingUsers}
      />
      {isEditingUsers ? null : (
        <UsersListPopoverFieldList
          onRemoveUser={handleRemoveUser}
          users={users}
          usersFetched={usersFetched}
          value={value}
          usersFetchingNextPage={usersFetchingNextPage}
          hasNextUsersPage={hasNextUsersPage}
          handleLoadMoreUsers={handleLoadMoreUsers}
        />
      )}
    </div>
  );
}

export default UsersListPopoverFieldControl;
