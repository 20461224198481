import React from 'react';

import {
  YesNoAnyRadioButtonsValue,
  YesNoAnyRadioButtonsOnChange
} from './YesNoAnyRadioButtons.types';

import { YesNoAnyRadioButtonsItem } from './helpers/YesNoAnyRadioButtonsItem';

import { words } from '../../locales/keys/words';

interface YesNoAnyRadioButtonsProps {
  name: string;
  value: YesNoAnyRadioButtonsValue;
  onChange: YesNoAnyRadioButtonsOnChange;
}

function YesNoAnyRadioButtons({
  name,
  value,
  onChange
}: YesNoAnyRadioButtonsProps) {
  return (
    <div className="text-sm">
      <div className="flex">
        <YesNoAnyRadioButtonsItem
          name={name}
          value={value}
          buttonValue={YesNoAnyRadioButtonsValue.YES}
          i18nText={words.yes}
          onChange={onChange}
        />
        <YesNoAnyRadioButtonsItem
          name={name}
          value={value}
          buttonValue={YesNoAnyRadioButtonsValue.NO}
          i18nText={words.no}
          onChange={onChange}
        />
        <YesNoAnyRadioButtonsItem
          name={name}
          value={value}
          buttonValue={YesNoAnyRadioButtonsValue.ANY}
          i18nText={words.any}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default YesNoAnyRadioButtons;
