import { Control, Controller, FieldPath } from 'react-hook-form';

import { ColorsSelect, ColorsSelectDataItem } from '../../ColorsSelect';

interface ColorsSelectFieldProps<T> {
  colors: ColorsSelectDataItem[];
  control: Control<T>;
  disabled?: boolean;
  isMulti?: boolean;
  name: FieldPath<T>;
}

function ColorsSelectField<T>({
  colors,
  control,
  isMulti = false,
  name,
  disabled
}: ColorsSelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <ColorsSelect
          disabled={disabled}
          isMulti={isMulti}
          items={colors}
          onChange={onChange}
          value={value as string[]}
        />
      )}
    />
  );
}

export default ColorsSelectField;
